// @src/pages/Lounge.jsx
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { getQrs, logOut } from '../services/Firebase';
import { useEffect, useState } from 'react';

const QRs = () => {
    const { user, setUser } = useAuth();
    const navigate = useNavigate();
    const [datas, setDatas] = useState({});
    
    useEffect(()=>{
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async (lng) => {
            const apps = await getQrs();
            setDatas(apps);
    }

    const logout = () => {
        logOut();
        setUser(null);
        navigate("/");
    };

    const gridContainer = {
        'display': 'flex',
        'flexWrap': 'wrap',
        'justifyContent': 'space-around',
        'width': '100%',
        'height': '100vh'

    };
  
    const gridItem = {
        'display': 'flex',
        'flexDirection': 'column',
        'alignItems': 'center',
        'margin': '10px',
        'textAlign': 'center'

    }
  
    const gridItemImg = {
        'maxWidth': '500px',
        'height': 'auto',
        'marginBottom': '5px' /* Espacio entre la imagen y el nombre */
      }

    return (
        <div>
            <div style={gridContainer} id='main'>
                { datas?.length > 0 &&
                    datas.map(item => (
                        <div style={gridItem}>
                            <img src={item.qr} alt='' style={gridItemImg} />
                            <p>{item.name}</p>
                        </div>
                    ))
                }
            </div>
            {/* <button onClick={logout}>Logout</button> */}
        </div>
    );
};
export default QRs;