import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider, RequireAuth } from "./context/Auth";
import Layout from "./components/Layout";
import HomePage from "./pages/Home";
import Condition from "./pages/Condition";
import Policy from "./pages/Policy";
import ChangeBattery from "./pages/ChangeBattery";
import LoginPage from "./pages/Login";
import NotFoundPage from "./pages/NotFound";
import QRsPage from "./pages/QRs";
import UnauthorizedPage from "./pages/Unauthorized";

const App = () => {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route element={<Layout />}>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/:id/politica-de-privacidad/:lang?" element={<Policy />} />
                        <Route path="/:id/condiciones-de-uso/:lang?" element={<Condition />} />
                        <Route path="/:id/cambiar-pila" element={<ChangeBattery />} />
                        
                        <Route path="*" element={<NotFoundPage />} />
                        <Route path="/unauthorized" element={<UnauthorizedPage />} />
                        <Route element={<RequireAuth />}>
                            <Route path="/qrs" element={<QRsPage />} />
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
  };
  export default App;
