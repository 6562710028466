import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getLogo, getCondition, getSiteName } from '../services/Firebase';
import LanguageSelector from '../components/LanguageSelector';
const _ = require('lodash');

// @src/pages/Policy.jsx
const Condition = () => {
    const { id, lang } = useParams();
    const [ lng, setLng ] = useState(lang || 'es');
    const [datas, setDatas] = useState({});
    const [img, setImg] = useState();
    const [name, setName] = useState('PETETE');

    useEffect(()=>{
        async function initData() {
            setImg(await getLogo(id));
            setName(await getSiteName(id));
        };
        initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        getData(lng);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lng]);

    const getData = async (lng) => {
        const ouch = _.cloneDeep(datas);
        if(ouch[lng] == null || ouch[lng].length <= 0) {
            const policy = await getCondition(id, lng);
            ouch[lng] = policy;
            setDatas(ouch);
        }
    }

    return (
      <div style={{maxWidth: '700px', margin: '0 auto'}} >
        <LanguageSelector onLanguageChange={setLng} lang={lng} />
        <div style={{with: '100%', textAlign: 'center', maxHeight: '220px', marginTop: '10px', marginBottom: '10px'}}>
            <img src={img} alt={id} style={{maxHeight: '200px'}} />
        </div>
        {datas[lng] && datas[lng].lines.map((element, index) => (
            <div key={index} dangerouslySetInnerHTML={{ __html: element.replace(/#SITENAME#/g, name) }} />
        ))}
      </div>
    );
  };
  export default Condition;