import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getFirestore, collection, getDoc, doc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

// Inicializa la aplicación de Firebase
const cfg = process.env.REACT_APP_FIREBASE;
const app = initializeApp(typeof cfg == 'string' ? JSON.parse(cfg) : cfg);
// const auth = getAuth(app);

const doLogin = async (user, password) => {
    let ret = null;
    try {
        ret = signInWithEmailAndPassword(getAuth(app), user, password);
        return ret;
    } catch (error) {
        console.log(error.message);
    }
    return ret;
}

const logOut = () => signOut;

const getPolicy = async (id, lang) => {
    const db = getFirestore(app);
    const pagesRef = collection(db, 'pages')
    let data = await getDoc(doc(pagesRef, `${id}-privacy-${lang}`));
    if(!data.exists()) {
        data = await getDoc(doc(pagesRef, `default-privacy-${lang}`));
        if(!data.exists()) {
            data = await getDoc(doc(pagesRef, `default-privacy-es`));
        }
    }
    return(data.data());
}

const getCondition = async (id, lang) => {
    const db = getFirestore(app);
    const pagesRef = collection(db, 'pages')
    let data = await getDoc(doc(pagesRef, `${id}-condition-${lang}`));
    if(!data.exists()) {
        data = await getDoc(doc(pagesRef, `default-condition-${lang}`));
        if(!data.exists()) {
            data = await getDoc(doc(pagesRef, `default-condition-es`));
        }
    }
    return(data.data());
}
const getSiteName = async (id, lang) => {
    const db = getFirestore(app);
    const collRef = collection(db, 'pages');
    let data = await getDoc(doc(collRef, `${id}-config`));
    const ret = data?.data()?.name || '';
    return(ret);
}

const getQrs = async () => {
    const db = getFirestore(app);
    const collRef = collection(db, 'pages');
    let data = await getDoc(doc(collRef, `qrs`));
    const ret = data?.data()?.apps || [];
console.log(ret);    
    return(ret);
}

const getLogo = async (path, imgage='logo.png') => {
    try {
        const storage = getStorage(app);
        const storageRef = ref(storage, `kaps-apps/${path}/${imgage}`);
        const img = await getDownloadURL(storageRef);
        return img;
    } catch (error) {
        return '';
    }
}

export {
    doLogin,
    logOut,
    getPolicy,
    getCondition,
    getSiteName,
    getQrs,
    getLogo
}