import React, { useState } from 'react';

const LanguageSelector = ({ onLanguageChange, lang='es' }) => { 
    const [selectedLanguage, setSelectedLanguage] = useState(lang); 
  
    const handleLanguageChange = (event) => {
      setSelectedLanguage(event.target.value);
      if (onLanguageChange) { 
        onLanguageChange(event.target.value); 
      }
    };

    const languages = [
        { code: 'es', name: 'Español', flag: '🇪🇸' },
        { code: 'en', name: 'English', flag: '🇬🇧' },
        /*
        { code: 'pt', name: 'Português', flag: '🇵🇹' },
        { code: 'ca', name: 'Català', flag: 'Catalan flag' },
        { code: 'fr', name: 'Français', flag: '🇫🇷' },
        { code: 'it', name: 'Italiano', flag: '🇮🇹' },
        { code: 'de', name: 'Deutsch', flag: '🇩🇪' },
        { code: 'ru', name: 'Русский', flag: '🇷🇺' },
        { code: 'pl', name: 'Polski', flag: '🇵🇱' },
        { code: 'at', name: 'Österreichisch', flag: '🇦🇹' },
        { code: 'gl', 'Galego', flag: 'Galician flag' },
        { code: 'va', 'Valencià', flag: 'Valencian flag' }
        */
    ];

    return (
        <div style={{position: 'absolute', top: '20px', right: '20px'}}>
            <select value={selectedLanguage} onChange={handleLanguageChange}>
            {languages.map((language) => (
                <option key={language.code} value={language.code}>
                {language.flag} {language.name}
                </option>
            ))}
            </select>
        </div>
    );
};

export default LanguageSelector;