// @src/pages/Login.jsx
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { doLogin } from '../services/Firebase';
const Login = () => {
    const { setUser } = useAuth();
    const navigate = useNavigate();
    const login = (
        async (e) => {
            e.preventDefault();
            const user = e.target[0].value;
            const pass =  e.target[1].value;
            const usr = await doLogin( user, pass);
            if(usr) {
                setUser({ usr });
                navigate("/qrs");
            } else {
                navigate("/login");
            }
        }
    );
  return (
    <div style={{maxWidth: '700px', margin: '0 auto', textAlign: 'center', height:'100vh'}}>
      <h1>Aquí va a ir el logo de Kaps</h1>
      <p>Sí púbica y centradita</p>
      <div style={{height: '80%', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center'}}>
        <form onSubmit={login} style={{
            textAlign: 'center',
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '0 auto'}}>
            <input placeholder="Usuario"/>
            <input type='password' placeholder='Contraseña'/>
            <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};
export default Login;