import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getLogo } from '../services/Firebase';

// @src/pages/Policy.jsx
const ChangeBattery = () => {
    const { id } = useParams();
    const [img, setImg] = useState();

    useEffect(()=>{
        async function getImg() {
            setImg(await getLogo(id, 'cambio-bateria-racvn.jpg'));
        };
        getImg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const mystyle = {
        with: '100%',
        height:'100vh',
        textAlign: 'center',
        backgroundColor:'#fdd400',
        display:'flex',
        alignItems:'center'
    };
    return (
        <div style={mystyle}>
            <div style={{maxWidth: '800px', margin: '0 auto'}} >
                <img src={img} alt={id} width='842px' />
            </div>
        </div>
    );
  };
  export default ChangeBattery;